import './App.css';

import React, { Component } from 'react';
import { ConsoleText } from './FakeWebConsole';
import Blog from './Blog.js'
import { BrowserRouter as Router} from "react-router-dom";


const links = [
  { title: 'GitHub', url: 'https://github.com/GregLando113' },
  { title: 'LinkedIn', url: 'https://www.linkedin.com/in/gregory-landowski-999979163/' },
  { title: 'Resume', url: 'https://landosolutions.com/resume.pdf' }
]


class LinkBar extends Component {
  render() {
    return (
      <div className="App-LinkBar">
        {links.map((e, idx) => (
          <div className="App-LinkBar-Link">
            <a href={e.url}>
              <ConsoleText showCursor text={e.title} />
            </a>
          </div>
        ))}
      </div>
    )
  }
};

class App extends Component {

  state = {
    showTitle: false,
    showLinkBar: false
  }
  render() {
    return (
      <Router>
        <div className="App">
          <div className="App-Title">
            <div className="App-MyName">
              <ConsoleText showCursor={!this.state.showTitle} text="Gregory Landowski" onWriteFinish={() => this.setState({showTitle: true})} />
            </div>
            { this.state.showTitle &&
              <div className="App-MyTitle">
                <ConsoleText showCursor={this.state.showTitle} text="Computer Engineer" onWriteFinish={() => this.setState({showLinkBar: true})} />
              </div>
            }
          </div>
          <div className="App-InfoBar">
            <LinkBar links={links} />
          </div>
          <Blog />
        </div>
      </Router>
    );
  }
  
};

export default App;
