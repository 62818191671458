import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';

export class BlogIndex extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            index: []
        }

        axios.get('/api/blog/index')
        .then((r) => {
            this.setState({
                index: r.data,
                loading: false
            })
        })
    }

    render() {
        if (this.state.loading)
            return null;

        return (
            <div className="BlogIndex">
                <h3>My Thoughts</h3>
                <hr style={{width: '100%'}} />
                {this.state.index.map(e => (
                    <Link className="BlogIndex-Link" to={`/blog/${e}`}>{e}</Link>
                ))}
            </div>
        )
    }
};


class Blog extends Component {


    render() {

        let route = this.props.location.pathname.split('/');


        return (
            <div>
                {route[1] == 'blog' && <iframe className="App-BlogPost" src={`https://landosolutions.com/api${this.props.location.pathname}`} />}
                <BlogIndex />
            </div>
        );
    }
}

export default withRouter(Blog);