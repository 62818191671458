import React, { Component } from 'react';


export class ConsoleText extends Component {

    constructor(props) {
        super(props);
        this.state = {
            curText: '',
            count: 0,
            cursor: this.props.showCursor
        };
        this.cursorInt = setInterval(this.onCursorTick.bind(this), 700);
        setTimeout(this.onTextTick.bind(this), this.textRandom());
    }

    textRandom() {
        return Math.round(20 + 20 * Math.random())
    }


    onCursorTick() {
        this.setState({ cursor: !this.state.cursor })
    }

    onTextTick() {
        let { curText, count } = this.state;
        let newText = curText + this.props.text[count];
        this.setState({ curText: newText, count: count + 1})
        if (this.props.text != this.state.curText) {
            setTimeout(this.onTextTick.bind(this), this.textRandom());
        }
        else {
            if (this.props.onWriteFinish) {
                this.props.onWriteFinish();
            }
        }
    }

    render() {
        return (
            <div style={this.props.style}>
                {this.state.curText}{this.props.showCursor && this.state.cursor && '_'}
            </div>
        );
    }

};